import React from 'react'
import styles from '../../../../shared/catalogo/producto/ficha/cta/cta.module.scss'
import { intl, t } from '../../../i18n'
import { isPromocionForProductos } from '../../../context/selectors/cestaSelectors'
import { NeumaticoInfo } from '../types'
import { ecotasaWithTax } from '../utils'

interface EcoTasaPriceProps {
  neumaticoInfo: NeumaticoInfo
  cantidad: number
}

const EcoTasaPrice: React.FC<EcoTasaPriceProps> = ({
  neumaticoInfo,
  cantidad,
}) => {
  const ecotasaTotal = ecotasaWithTax(neumaticoInfo.producto)
  const importeFinal =
    neumaticoInfo.promocion && isPromocionForProductos(neumaticoInfo.promocion)
      ? neumaticoInfo.importeConEcotasa -
        neumaticoInfo.promocion.importePromocion
      : neumaticoInfo.importeConEcotasa

  return (
    <div className={styles.ecotasa}>
      {t('catalogo.producto.ficha.con_ecotasa', {
        ecotasa: intl.formatNumber(ecotasaTotal, {
          style: 'currency',
          currency: 'EUR',
        }),
      })}
      <span className={styles.ecotasa_price_text}>
        &nbsp;
        {intl.formatNumber(importeFinal, {
          style: 'currency',
          currency: 'EUR',
        })}
      </span>
    </div>
  )
}

export default EcoTasaPrice
