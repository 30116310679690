import { graphql, PageProps } from 'gatsby'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { locale, t, textWithLocale } from '../../i18n'
import route from '../../utils/route'
import Layout from '../../components/Layout'
import { makeNeumaticosSchema } from '../../utils/schema'
import {
  estacionesWithIcons,
  getModeloImagenes,
  getModeloMarca,
  getNeumaticoEstacion,
  getNeumaticoSpecs,
  priceWithTax,
  productoActivo,
} from '../../components/FichasNeumaticos/utils'
import { useSelectedMenu } from '../../../../sites/rodi/helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../../../sites/rodi/constants/menu'
import RequestTyreCTA from '../../components/FichasNeumaticos/Ctas/RequestTyreCTA'
import { CategoriaNeumatico, RequestTyreData } from '../../types/Neumatico'
import { Product, Promocion } from '../../context/reducers/cestaReducer'
import AppContext from '../../context/context'
import { productInfoFromId } from '../../api/cesta'
import { isPromocionForProductos } from '../../context/selectors/cestaSelectors'
import CatalogoFicha from '../../../shared/catalogo/producto/ficha/CatalogoFicha'
import WhiteBoxContainer from '../../../shared/components/WhiteBoxContainer'
import { initialNeumaticoOptions } from '../../components/cesta/cestaItem/CestaItem'
import { generateNeumaticoCocheTags } from '../../components/FichasNeumaticos/Tags/generateNeumaticoCocheTags'
import useUpdatePromocion from '../../helpers/customHooks/useUpdatePromocion'
import { getFetchFunction } from '../../../shared/catalogo/producto/ficha/promocion/getFetchFunction'
import Montaje from '../../../shared/catalogo/producto/ficha/cta/Montaje'
import ComprarProductoCTA from '../../../shared/catalogo/producto/ficha/cta/ComprarProductoCTA'
import EcoTasaPrice from '../../components/FichasNeumaticos/Ctas/EcoTasaPrice'
import { CatalogoProductInfo } from '../../../shared/catalogo/producto/ficha/CatalogoProductInfo'
import RequestTyre from '../../components/RequestTyre/RequestTyre'
import Modal from '../../../shared/components/Modal/Modal'
import logic from '../../logic'

const FichaNeumaticosPage = ({
  data: {
    modeloInfo,
    neumaticoInfo,
    seoData,
    banners_es_desktop,
    banners_ca_desktop,
    banners_pt_desktop,
    banners_es_mobile,
    banners_ca_mobile,
    banners_pt_mobile,
  },
}: PageProps<Queries.FichaNeumaticosPageQuery>) => {
  useSelectedMenu(MenuPrincipal.NEUMATICOS)

  const {
    modelo,
    marca: { nombre: marca },
    imagenes,
    slug,
  } = modeloInfo

  const banners = {
    ca_desktop: banners_ca_desktop.nodes,
    ca_mobile: banners_ca_mobile.nodes,
    es_desktop: banners_es_desktop.nodes,
    es_mobile: banners_es_mobile.nodes,
    pt_desktop: banners_pt_desktop.nodes,
    pt_mobile: banners_pt_mobile.nodes,
  }
  const { userInfo } = useContext(AppContext)
  const { fichas } = getModeloImagenes(imagenes)

  const [showModal, setShowModal] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [selectedNeumatico, setSelectedNeumatico] = useState(
    null as RequestTyreData | null
  )
  const [productWithInfo, setProductWithInfo] = useState({} as Product)

  useEffect(() => {
    productInfoFromId(neumaticoInfo.id_site_producto.toString(), userInfo).then(
      (product) => {
        setProductWithInfo(product)
      }
    )
  }, [])

  const updatedNeumaticoInfo = useMemo(() => {
    const mergedProduct = {
      ...neumaticoInfo.producto,
      ...productWithInfo,
    }

    return {
      ...neumaticoInfo,
      producto: {
        ...mergedProduct,
        disponibilidad:
          mergedProduct.disponibilidad ?? productoActivo(mergedProduct), // aqui si nos ha devuelto algo raro productWithInfo no tendremos el campo disponibilidad de DB, asi que miramos con activo_rodi/ep de NeumaticoProducto
      },
    }
  }, [neumaticoInfo, productWithInfo])

  const closeModal = () => {
    setSuccessMessage(false)
    setShowModal(false)
  }

  const PriceTaxPromotion = useMemo(() => {
    return neumaticoInfo.promocion &&
      isPromocionForProductos(neumaticoInfo.promocion as unknown as Promocion)
      ? neumaticoInfo.importe - neumaticoInfo.promocion.importePromocion
      : priceWithTax(neumaticoInfo.producto)
  }, [neumaticoInfo])

  const updatedPromotion = useUpdatePromocion({
    id_site_marca_neumatico: modeloInfo.id_site_marca_neumatico,
    id_site_modelo_neumatico: modeloInfo.id_site_modelo_neumatico,
    llanta: undefined,
    runflat: undefined,
    allseason: undefined,
    invierno: undefined,
    postCode: null,
    fetchFunction: getFetchFunction('neumatico'),
  })

  const catalogoProductInfo: CatalogoProductInfo = {
    ...updatedNeumaticoInfo,
    ...modeloInfo,
    nombre_producto: updatedNeumaticoInfo.producto[`nombre_producto_${locale}`],
    estaciones: estacionesWithIcons([
      getNeumaticoEstacion(updatedNeumaticoInfo),
    ]),
    productInfo: {
      id: updatedNeumaticoInfo.producto.id_navision,
      type: 'neumatico',
      categoria: modeloInfo.marca.clasificacion as CategoriaNeumatico,
      marca,
    },
  }

  const getCTAButton = () => {
    const isLoading =
      !updatedNeumaticoInfo?.producto ||
      Object.keys(productWithInfo).length === 0
    const isAvailable = updatedNeumaticoInfo?.producto?.disponibilidad > 0

    return (
      <>
        {isAvailable ? (
          <ComprarProductoCTA
            catalogoProductInfo={catalogoProductInfo}
            options={initialNeumaticoOptions}
            ecoTasaPrice={
              <EcoTasaPrice neumaticoInfo={neumaticoInfo} cantidad={1} />
            }
            bannerPromocion={updatedPromotion}
            montaje={
              <Montaje servicios={neumaticoInfo.producto?.servicio_montaje} />
            }
            disabled={isLoading || !isAvailable} // Always render but disable if loading/unavailable
          />
        ) : (
          !isLoading && (
            <section>
              <WhiteBoxContainer className={'yellow'}>
                <RequestTyreCTA
                  modelo={modeloInfo}
                  neumatico={updatedNeumaticoInfo}
                  openModal={setShowModal}
                  selectNeumatico={setSelectedNeumatico}
                  flexDirection={'column'}
                />
              </WhiteBoxContainer>
            </section>
          )
        )}
      </>
    )
  }

  const CTAButton = getCTAButton()

  return (
    <Layout
      selector={false}
      seoData={seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.neumaticos'),
          link: route('neumaticos.index'),
        },
        {
          display: marca,
          link: route('neumaticos.marca', {
            marca: marca.toLowerCase(),
          }),
        },
        {
          display: modelo,
          link: route('neumaticos.modelo', {
            marca: marca.toLowerCase(),
            modelo: slug,
          }),
        },
        {
          display: getNeumaticoSpecs(neumaticoInfo),
        },
      ]}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            makeNeumaticosSchema({
              name: `${getModeloMarca(modeloInfo)} ${getNeumaticoSpecs(
                neumaticoInfo
              )}`,
              image: fichas.length && fichas[0].url,
              description: modelo[textWithLocale('recomendado_para')],
              brand: marca,
              price: PriceTaxPromotion.toString(),
            })
          ),
        }}
      />
      <CatalogoFicha
        catalogoProductInfo={catalogoProductInfo}
        specifications={[
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.marca'
            ),
            value: marca,
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.modelo'
            ),
            value: modeloInfo.modelo,
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.estacion'
            ),
            value: getNeumaticoEstacion(neumaticoInfo),
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.medidas'
            ),
            value: getNeumaticoSpecs(neumaticoInfo),
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.marcaje'
            ),
            value: neumaticoInfo.marcaje,
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.runflat'
            ),
            value: neumaticoInfo.runflat
              ? t('landing_neumaticos.titulos_caracteristicas_tecnicas.si')
              : t('landing_neumaticos.titulos_caracteristicas_tecnicas.no'),
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.antipinchazo'
            ),
            value: neumaticoInfo.tipo_antipinchazo,
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.reforzada'
            ),
            value: neumaticoInfo.reforzada,
          },
          {
            label: t(
              'landing_neumaticos.titulos_caracteristicas_tecnicas.vehiculo'
            ),
            value: neumaticoInfo.vehiculo,
          },
        ]}
        banners={banners}
        cta={CTAButton}
        tags={generateNeumaticoCocheTags(neumaticoInfo)}></CatalogoFicha>
      <Modal
        title={t('landing_neumaticos.neumatico_item.disponibilidad')}
        closeModal={closeModal}
        showModal={showModal}>
        <RequestTyre
          successMessage={successMessage}
          setSuccessMessage={setSuccessMessage}
          selectedNeumatico={selectedNeumatico}
          closeModal={closeModal}
          requestAvailability={({
            email,
            selectedNeumatico,
            device,
            precio,
            idioma,
            cantidad = 1,
          }) =>
            logic.requestNeumaticoAvailability(
              email,
              selectedNeumatico.marca,
              selectedNeumatico.modelo,
              selectedNeumatico.id_site_modelo_neumatico,
              selectedNeumatico.id_site_producto,
              selectedNeumatico.tyreSpecs,
              device,
              precio,
              idioma,
              cantidad
            )
          }
        />
      </Modal>
    </Layout>
  )
}
export const pageQuery = graphql`
  query FichaNeumaticosPage(
    $idModelo: Int!
    $idSiteProductoNeumatico: Int!
    $url: String!
    $banners_es_desktop: String!
    $banners_ca_desktop: String!
    $banners_pt_desktop: String!
    $banners_es_mobile: String!
    $banners_ca_mobile: String!
    $banners_pt_mobile: String!
  ) {
    modeloInfo: modelos(id_site_modelo_neumatico: { eq: $idModelo }) {
      ...modeloInfo
      ...modeloMarca
      ...modeloImagen
    }

    neumaticoInfo: neumaticos(
      id_site_productos_neumaticos: { eq: $idSiteProductoNeumatico }
    ) {
      ...neumaticoInfo
      ...productoInfo
    }
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
    banners_ca_desktop: allS3Asset(
      filter: { Key: { glob: $banners_ca_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_ca_mobile: allS3Asset(
      filter: { Key: { glob: $banners_ca_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_desktop: allS3Asset(
      filter: { Key: { glob: $banners_es_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_es_mobile: allS3Asset(
      filter: { Key: { glob: $banners_es_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_pt_desktop: allS3Asset(
      filter: { Key: { glob: $banners_pt_desktop } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    banners_pt_mobile: allS3Asset(
      filter: { Key: { glob: $banners_pt_mobile } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`

export default FichaNeumaticosPage
