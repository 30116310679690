import { NeumaticoCocheInfo } from '../types'
import { Tag } from '../../../../shared/catalogo/producto/ficha/Tags/Tags'
import { getNeumaticoEstacion } from '../utils'
import { t, textWithLocale } from '../../../i18n'

export const generateNeumaticoCocheTags = (
  neumatico: NeumaticoCocheInfo
): Tag[] => {
  const tipoVehiculo =
    neumatico.site_tipo_vehiculo[textWithLocale('tipo_vehiculo')]
  const estacion = getNeumaticoEstacion(neumatico)
  const reforzada = neumatico.reforzada
  const M_S = neumatico.m_s ? 'M + S' : null
  const pmsf = null
  const vehiculo = neumatico.vehiculo
  return [
    tipoVehiculo && {
      label: tipoVehiculo,
      tooltip: t('catalogo.producto.ficha.section_tags.tooltip_vehiculo', {
        vehiculo: tipoVehiculo,
      }),
    },
    estacion && {
      label: estacion,
      tooltip: t('catalogo.producto.ficha.section_tags.tooltip_estacion', {
        estacion,
      }),
    },
    reforzada && {
      label: reforzada,
      tooltip: t('catalogo.producto.ficha.section_tags.tooltip_reforzada', {
        reforzada,
      }),
    },
    vehiculo && {
      label: vehiculo,
      tooltip: t('catalogo.producto.ficha.section_tags.tooltip_vehiculo', {
        vehiculo,
      }),
    },
    pmsf && {
      label: pmsf,
      tooltip: t('catalogo.producto.ficha.section_tags.tooltip_3pmsf'),
    },
    M_S && {
      label: M_S,
      tooltip: t('catalogo.producto.ficha.section_tags.tooltip_m_s'),
    },
  ].filter(Boolean)
}
